<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <KTCodePreview v-bind:title="'Danh sách chương trình thu cũ- đổi mới'">
      <template v-slot:toolbar>
        <div class="row">
          <div>
            <b-dropdown size="sm" id="dropdown-1" right>
              <template slot="button-content">
                <i style="font-size: 1rem" class="fas fa-cog"></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item @click="excelModal">
                <span>
                  <i style="font-size: 1rem" class="far fa-file-excel"></i>
                  &nbsp; Xuất Excel</span
                >
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="ml-3">
            <router-link
              to="/tradein-programs/add"
              v-if="checkPermission('TRADE_IN_PROGRAM_INSERT')"
            >
              <button
                v-bind:style="btnCreate"
                type="button"
                class="btn btn-primary font-weight-bolder btn-sm"
              >
                <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm mới
              </button>
            </router-link>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <div class="col-md-12 mb-5">
          <b-form>
            <b-row class="mb-5">
              <b-col style="padding-left: 0">
                <b-input
                  size="sm"
                  placeholder="Tìm kiếm"
                  v-model="searchName"
                  class="btn-sm"
                  v-on:keyup.enter="onFilter()"
                ></b-input>
              </b-col>
              <b-col>
                <b-input
                  size="sm"
                  placeholder="Tìm theo sản phẩm"
                  v-model="searchProduct"
                  class="btn-sm"
                  v-on:keyup.enter="onFilter()"
                ></b-input>
              </b-col>
              <b-col></b-col>
              <b-col></b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col md="1" style="padding-left: 0">
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="onFilter()"
                  >Lọc</b-button
                >
              </b-col>
            </b-row>
          </b-form>
        </div>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table
          :items="listItem"
          :fields="fields"
          hover
          bordered
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>
          <template v-slot:cell(isActive)="row">
            <span
              v-if="row.item.isActive === 1"
              v-text="'Hoạt động'"
              class="
                label
                font-weight-bold
                label-lg label-light-success label-inline
              "
            ></span>
            <span
              v-else
              v-text="'Không hoạt động'"
              class="
                label
                font-weight-bold
                label-lg label-light-danger label-inline
              "
              style="width: max-content"
            ></span>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item
                  @click="copyItem(row.item)"
                  v-if="checkPermission('TRADE_IN_PROGRAM_INSERT')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1.05rem" class="far fa-copy"></i>
                    &nbsp; Sao chép
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="checkViewOnly()"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon-eye"></i>
                    &nbsp; Chi tiết
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="!checkViewOnly()"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDeleteAlert(row.item)"
                  v-if="checkPermission('TRADE_IN_PROGRAM_DELETE')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số:
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="customPagination"
              v-show="totalPages >= 2"
              :link-gen="linkGen"
              :number-of-pages="totalPages"
              use-router
              @change="fetchData()"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
      </template>
    </KTCodePreview>
    <b-modal ref="excel-modal" hide-footer title="Xuất excel" id="excel-modal">
      <v-form ref="form" lazy-validation>
        <div class="mb-4">
          <div class="row">
            <div class="col-6">
              <b-form-group label="Chọn cột cần xuất:">
                <b-form-radio-group
                  id="radio-group-excel-1"
                  v-model="selectedRowExcel"
                  name="radio-sub-component-1"
                  @change.native="showHeader"
                >
                  <b-form-radio value="all">Tất cả</b-form-radio>
                  <b-form-radio value="row">Chọn cột</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group label="Danh sách cần xuất:">
                <b-form-radio-group
                  id="radio-group-excel-2"
                  v-model="selectedListExcel"
                  name="radio-sub-component-2"
                >
                  <b-form-radio value="current">Trang hiện tại</b-form-radio>
                  <!-- <b-form-radio value="all">Tất cả các trang</b-form-radio> -->
                </b-form-radio-group>
              </b-form-group>
            </div>
            <div class="col-6" v-show="showHeaderExcel">
              <b-form-group>
                <template #label>
                  Cột:<br />
                  <b-form-checkbox
                    v-model="allSelected"
                    :indeterminate="indeterminate"
                    aria-describedby="items"
                    aria-controls="items"
                    @change="toggleAll"
                  >
                    {{ allSelected ? 'Bỏ chọn tất cả' : 'Tất cả' }}
                  </b-form-checkbox>
                </template>
                <b-form-checkbox-group
                  id="items"
                  v-model="selectedHeader"
                  :options="optionsHeader"
                  name="items"
                  stacked
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>
        </div>

        <b-button
          style="width: 110px"
          variant="primary"
          size="sm"
          @click="reportClick"
          class="mr-3"
        >
          <v-icon small class="text-white" v-b-tooltip>mdi-download</v-icon>
          <strong>Xuất Excel</strong>
        </b-button>

        <b-button
          style="width: 100px"
          variant="secondary"
          size="sm"
          @click="$bvModal.hide('excel-modal')"
        >
          <strong>Hủy</strong>
        </b-button>
      </v-form>
    </b-modal>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import localData from '@/utils/saveDataToLocal';
import { mapGetters } from 'vuex';
import Loader from '@/view/content/Loader.vue';
import { VclTable } from 'vue-content-loading';
import axios from 'axios';
import { BASE_URL } from '@/utils/constants';
import { getToastInstance } from '@/utils/toastHelper';

export default {
  data() {
    return {
      listItem: [],
      onLoading: false,
      btnCreate: {
        fontWeight: '600!important',
      },
      totalPages: 1,
      totalItems: 0,
      page: 1,
      searchName: '',
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      fields: [
        {
          key: 'count',
          label: 'STT',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Tên',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '25%',
          },
        },
        {
          key: 'description',
          label: 'Mô tả',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '30%' },
        },
        {
          key: 'startDate',
          label: 'Ngày bắt đầu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'endDate',
          label: 'Ngày kết thúc',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'isActive',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'actions',
          label: '',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
      ],
      selectedRowExcel: 'all',
      selectedListExcel: 'current',
      showHeaderExcel: false,
      indeterminate: false,
      allSelected: false,
      optionsHeader: [],
      selectedHeader: [],
      optionsHeaderDefault: [],
      count: 0,
    };
  },
  components: {
    KTCodePreview,
    Loader,
    VclTable,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Chương trình thu cũ- đổi mới', route: '/tradein-programs' },
      { title: 'Danh sách chương trình thu cũ- đổi mới' },
    ]);
  },
  created() {
    this.fetchData();
  },
  watch: {
    selectedHeader(newVal) {
      if (newVal.length === 0) {
        this.indeterminate = false;
        this.allSelected = false;
      } else if (newVal.length === this.optionsHeader.length) {
        this.indeterminate = false;
        this.allSelected = true;
      } else {
        this.indeterminate = true;
        this.allSelected = false;
      }
    },
  },
  methods: {
    ...getToastInstance(),
    editItem: function (item) {
      this.$router.push({
        name: 'update-tradein-program',
        query: { id: item.id },
      });
    },
    fetchData: async function () {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      if (!this.searchName) {
        this.searchName = '';
      }
      if (!this.searchProduct) {
        this.searchProduct = '';
      }

      const params = {
        page: this.page,
        limit: 10,
        name: this.searchName,
        searchProduct: this.searchProduct,
      };
      ApiService.query('tradeInProgram', { params }).then((response) => {
        const result = response.data.data.data;
        this.totalPages = response.data.data.total_page;
        this.totalItems = response.data.data.total_row;
        this.listItem = result.map((item, index) => {
          return {
            count: this.page >= 2 ? index + 1 + this.page * 10 - 10 : index + 1,
            id: item.id,
            name: item.name,
            description: item.description,
            isActive: item.status,
            startDate: item.startDate,
            endDate: item.endDate,
          };
        });
      });
    },
    deleteItem: async function (item) {
      const id = item.id;
      ApiService.delete(`tradeInProgram/${id}`).then((data) => {
        const { status, message } = data.data;
        if (status === 1) {
          this.makeToastSuccess(message);
          this.fetchData();
        } else {
          this.makeToastFailure(message);
        }
      });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa chương trình thu cũ đổi mới!',
        text: 'Bạn có chắc muốn xóa chương trình thu cũ đổi mới này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    checkViewOnly: function () {
      let count = 0;
      if (localData.checkPermission('TRADE_IN_PROGRAM_VIEW')) {
        count++;
      }
      if (localData.checkPermission('TRADE_IN_PROGRAM_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
    excelModal: async function () {
      this.optionsHeaderDefault = [
        { text: 'ID', value: 'id', disabled: true },
        { text: 'Mã', value: 'code', disabled: true },
        { text: 'Tên', value: 'name', disabled: true },
      ];
      this.selectedHeader = [
        'id',
        'code',
        'name',
        'description',
        'createdBy',
        'startDate',
        'endDate',
        'createdAt',
        'status',
      ];
      this.optionsHeader = [
        { text: 'ID', value: 'id', disabled: true },
        { text: 'Mã', value: 'code', disabled: true },
        { text: 'Tên', value: 'name', disabled: true },
        { text: 'Mô tả', value: 'description', disabled: false },
        { text: 'Tạo bởi', value: 'createdBy', disabled: false },
        { text: 'Ngày bắt đầu', value: 'startDate', disabled: false },
        { text: 'Ngày kết thúc', value: 'endDate', disabled: false },
        { text: 'Ngày tạo', value: 'createdAt', disabled: false },
        { text: 'Trạng thái', value: 'status', disabled: false },
      ];
      this.$refs['excel-modal'].show();
    },
    reportClick: async function () {
      let data = {
        items: this.listItem,
        headers: this.selectedHeader,
        params: null,
      };
      if (this.selectedRowExcel !== 'row') {
        data.headers = this.optionsHeader.map((x) => x.value);
      }
      if (this.selectedListExcel !== 'current') {
        if (
          this.selectedCategory !== null ||
          this.search !== '' ||
          this.selectedStatus !== '' ||
          this.selectedProductType !== ''
        ) {
          this.childProductId = '';
          this.parentProductId = '';
        }
        if (this.selectedStore === null) {
          this.selectedStore = 0;
        }
        data.params = {
          name: this.search,
          limit: 1000000,
          page: 1,
          categoryId: this.selectedCategory,
          currentSort: this.currentSort,
          currentSortDir: this.currentSortDir,
          status: this.selectedStatus,
          productType: this.selectedProductType,
          parentProductId: this.parentProductId,
          childProductId: this.childProductId,
          storeId: this.selectedStore,
        };
        data.items = [];
      }
      let baseUrl = BASE_URL;

      //let baseUrl = 'http://localhost:3000/api/';
      let url = `${baseUrl}tradeInProgram/export-excel`;
      await axios({
        url: url,
        method: 'POST',
        responseType: 'blob',
        data: data,
      }).then((response) => {
        let headerLine = response.headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('"') + 1;
        let endFileNameIndex = headerLine.lastIndexOf('"');
        let filename = headerLine.substring(
          startFileNameIndex,
          endFileNameIndex
        );
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    showHeader() {
      if (this.selectedRowExcel === 'row') {
        this.showHeaderExcel = true;
      } else {
        this.showHeaderExcel = false;
      }
    },
    toggleAll(checked) {
      this.selectedHeader = checked
        ? this.optionsHeader.map((x) => x.value)
        : this.optionsHeaderDefault.map((x) => x.value);
    },
    copyItem: function (item) {
      this.$router.push({
        name: 'add-tradein-program',
        query: { id: item.id },
      });
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-tradein-program',
      });
      this.fetchData();
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
  },
};
</script>

<style>
.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>
